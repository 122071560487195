.about-row {
    padding: 2%;
}

.container-outline {
    border-style: solid;
    border-width: 8px;
    border-color: #465bd1 ;
    color: #0d793a;
    font-size: large;
}

.container-no-outline {
    color: #0d793a;
    font-size: large;
}

.contact-row {
    padding: 2%;
}

.contact-outline {
    border-style: solid;
    border-width: 6px;
    border-color: #641919;
    color: #641919;
    font-size: large;
}

h1 {
    font-family: 'Prata', serif;
    padding: 20px;
}

.navbar-blue {
    background-color: #465bd1;
    font-size: x-large;
    font-family: 'Prata', serif;
    font-weight: 500;
}

p {
    padding: 20px;
    text-align: center;
    font-weight: 500;
}

p a {
    font-family: 'Prata', serif;
    font-weight: bold;
}

.site-footer {
    background-color: #465bd1;
    padding: 20px;
    font-family: 'Prata', serif;
    color: white;
    font-weight: 500;
}

.why-row {
    padding: 2%;
}

.why-outline {
    border-style: solid;
    border-width: 6px;
    border-color: #b67703 ;
    color: #b67703;
    font-size: large;
}

/* To change height of binary container containing photo based on device width*/
@media screen and (max-width: 1050px) {
    .binary-row {
        height: 190px;    
    }
}

@media screen and (max-width: 550px) {
    .binary-row {
        height: 135px;    
    }
}